@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdRegular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdBold.otf');
    font-weight: 700;
}

body {
    margin: 0;
    font-family:
        'BrownStd',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-draggable {
    z-index: 10 !important;
}

.on-hover:hover .hovered {
    display: block;
}

.slider .thumbs {
    transform: none !important;
    text-align: left;
  }
  
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 1px solid #333 !important;
  }
  
  .carousel .thumbs-wrapper {
    margin: 20px 0 !important;
  }
  
  .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
    top: 50% !important;
    bottom: auto !important;
    margin-top: -25px !important;
    padding: 30px 10px !important;
  }
  
  .carousel.carousel-slider .control-arrow::before {
    display: none !important;
  }

  .carousel .control-dots .dot {
    border: 1px solid black;
  }

  .carousel .control-dots .dot.selected {
    background: black;
  }
  
  .carousel .control-next.control-arrow {
    background: url("../assets/corausel-right-arrow.png") 0 0 no-repeat !important;
    right: 0 !important;
  }
  
  .carousel .control-prev.control-arrow {
    background: url("../assets/corausel-left-arrow.png") 0 0 no-repeat !important;
    left: 0 !important;
  }